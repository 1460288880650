import axios from 'axios';
import jwtDecode from 'jwt-decode';

const Auth = {
  isAuthenticated: async () => {
    const token = localStorage.getItem('token');
    const isTokenValid = () => {
      const decodedToken = jwtDecode(token);
      const now = Math.floor(Date.now() / 1000);
      return decodedToken.exp > now;
    };

    if (token && isTokenValid()) {
      return true;
    }

    return false;
  },
  logout: () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authkey;
  },
  login: (isLoginPage, phone, code, hash, name) => {
    if (isLoginPage) {
      const params = new URLSearchParams({
        phone,
        code,
        hash,
      });

      return axios.post('/auth/login', params);
    }

    const params = new URLSearchParams({
      phone,
      code,
      hash,
      name,
    });

    return axios.post('/auth/register', params);
  },
  refreshToken: () => {
    const params = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refreshToken'),
    });

    return Auth.createAndSetTokens(params);
  },
  createAndSetTokens: async (token) => {
    if (token) {
      localStorage.setItem('token', token);
      axios.defaults.headers.common.Authkey = token;
      return token;
    }

    Auth.logout();
    return false;
  },
  getVerificationCode: async ({ phone, isLoginPage }) =>
    axios.post('/auth/code', { phone, isLoginPage }),
};

export default Auth;
