import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Nav } from 'react-bootstrap';
import {
  faTachometerAlt,
  faArchive,
  faMousePointer,
  faShoppingBasket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './sidebar.sass';

const Sidebar = () => (
  <Row className="sidebar-wrapper">
    <Nav className="sidebar">
      <NavLink exact className="sidebar-item" activeClassName="active" to="/">
        <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
        Dashboard
      </NavLink>
      <NavLink
        exact
        className="sidebar-item"
        activeClassName="active"
        to="/categories"
      >
        <FontAwesomeIcon className="icon" icon={faArchive} />
        Site Kategorileri
      </NavLink>
      <NavLink
        exact
        className="sidebar-item"
        activeClassName="active"
        to="/domains"
      >
        <FontAwesomeIcon className="icon" icon={faMousePointer} />
        Alan Adları
      </NavLink>
      <NavLink
        exact
        className="sidebar-item"
        activeClassName="active"
        to="/x-page-2"
      >
        <FontAwesomeIcon className="icon" icon={faShoppingBasket} />
        Siparişler
      </NavLink>
      <NavLink
        exact
        className="sidebar-item"
        activeClassName="active"
        to="/x-page-3"
      >
        <FontAwesomeIcon className="icon" icon={faUsers} />
        Kullanıcılar
      </NavLink>
    </Nav>
  </Row>
);

export default Sidebar;
