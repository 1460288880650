import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from 'components/loading/Loading';
import Header from 'components/header/Header';
import Sidebar from 'components/sidebar/Sidebar';

const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Account = lazy(() => import('pages/account/Account'));
const Credit = lazy(() => import('pages/credit/Credit'));
const Payment = lazy(() => import('pages/credit/Payment'));

const Category = lazy(() => import('pages/category/Category'));
const EditCategory = lazy(() => import('pages/category/EditCategory'));
const Domain = lazy(() => import('pages/domain/Domain'));
const EditDomain = lazy(() => import('pages/domain/EditDomain'));

const Main = () => (
  <Router>
    <Container className="no-gutters" fluid>
      <Row>
        <Col md={12}>
          <Header />
        </Col>
        <Col md={2}>
          <Sidebar />
        </Col>
        <Col md={10}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/credit" component={Credit} />
              <Route
                exact
                path="/credit/payment/:orderId"
                component={Payment}
              />

              <Route exact path="/categories" component={Category} />
              <Route exact path="/categories/:id" component={EditCategory} />
              <Route exact path="/domains" component={Domain} />
              <Route exact path="/domains/:id" component={EditDomain} />
            </Switch>
          </Suspense>
        </Col>
      </Row>
    </Container>
  </Router>
);

export default Main;
