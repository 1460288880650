import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Button, Dropdown } from 'react-bootstrap';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Auth from 'services/auth';

import logo from 'assets/img/logo.png';
import './header.sass';

const Header = () => {
  const logout = () => {
    Auth.logout();

    window.location = '/';
  };

  return (
    <Row className="header-wrapper">
      <Col className="logo-container" md={2}>
        <Link to="/">
          <Image className="logo" src={logo} />
        </Link>
      </Col>
      <Col className="header-container" md={10}>
        <Link to="/credit">
          <Button className="primary-outline-button mr-3">
            Bakiye: 1090.50₺
          </Button>
        </Link>
        <Dropdown className="account-button">
          <Dropdown.Toggle className="account-toggle primary-outline-button">
            <FontAwesomeIcon className="icon" icon={faUser} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Link className="dropdown-item" to="/account">
              Hesabım
            </Link>
            <Dropdown.Item onClick={logout}>Çıkış Yap</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Header;
