import React from 'react';
import Login from 'pages/login/Login';
import Main from 'pages/Main';
import Loader from 'components/loading/Loading';
import useAuth from 'hooks/useAuth';

import './app.sass';

const App = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  if (isAuthenticated == null) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Main />;
  }

  return <Login setIsAuthenticated={setIsAuthenticated} />;
};

export default App;
