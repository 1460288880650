import { useContext } from 'react';
import { ToastContext } from 'contexts/toastContext';

export default function useToast() {
  const { toastData } = useContext(ToastContext);

  return {
    info(message, duration) {
      toastData({
        message,
        duration,
        type: 'info',
        show: true,
      });
    },
    success(message, duration) {
      toastData({
        message,
        duration,
        type: 'success',
        show: true,
      });
    },
    warning(message, duration) {
      toastData({
        message,
        duration,
        type: 'warning',
        show: true,
      });
    },
    error(message, duration) {
      toastData({
        message,
        duration,
        type: 'error',
        show: true,
      });
    },
  };
}
