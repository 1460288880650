import axios from 'axios';
import config from 'config';

// Set base Axios URL
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common.Authkey = localStorage.getItem('token');

const onResponse = (response) => {
  const { data } = response;

  return { data, error: null };
};

const onResponseFailed = (err) => {
  const result = err?.response?.data;

  const error = result?.message || result?.error || err.message;
  return { error, data: null };
};

axios.interceptors.response.use(onResponse, onResponseFailed);
