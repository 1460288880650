import React, { createContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

const ToastContextProvider = ({ children }) => {
  const [toastData, setToastData] = useState({
    type: 'info',
    message: '',
    duration: 7000,
    show: false,
  });

  if (toastData.show) {
    toast(toastData.message, {
      position: 'top-right',
      duration: toastData.duration,
      type: toastData.type,
    });
  }

  const clearToast = () => setToastData((prev) => ({ ...prev, show: false }));

  return (
    <ToastContext.Provider value={{ toastData: setToastData }}>
      {children}
      <ToastContainer onClose={clearToast} />
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastContextProvider };
