import React from 'react';
import { Spinner } from 'react-bootstrap';

import './loading.sass';

const Loading = () => (
  <div className="loading-wrapper">
    <Spinner animation="grow" />
  </div>
);

export default Loading;
