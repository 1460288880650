import React, { useState } from 'react';
import { Row, Col, Card, Form, InputGroup, Button } from 'react-bootstrap';
import Loading from 'components/loading/Loading';
import Auth from 'services/auth';
import useToast from 'hooks/useToast';

import './login.sass';

const Login = ({ setIsAuthenticated }) => {
  const [loading, setLoading] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(0);
  const [code, setCode] = useState(0);
  const [hash, setHash] = useState(null);
  const [codeSendStatus, setCodeSendStatus] = useState(null);

  const toast = useToast();

  const handleClickAction = async () => {
    if (phone.length !== 10 || code.length !== 6 || (!isLoginPage && !name)) {
      toast.warning(
        isLoginPage
          ? 'Telefon numaranızı veya doğrulama kodunu doğru formatta giriniz.'
          : 'Bilgilerinizi eksiksiz doldurunuz!',
      );
      return;
    }

    setLoading(true);
    const { data, error } = await Auth.login(
      isLoginPage,
      phone,
      code,
      hash,
      name,
    );

    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    if (data?.token) {
      Auth.createAndSetTokens(data.token);
      setIsAuthenticated(true);
    }
  };

  const handleSendCode = async () => {
    setLoading(true);

    if (phone.length !== 10) {
      toast.warning('Telefon numaranızı doğru formatta giriniz.');
      return;
    }

    const { data, error } = await Auth.getVerificationCode({
      phone,
      isLoginPage,
    });

    if (error) {
      toast.error(error);
      setCodeSendStatus(false);
      setLoading(false);
      return;
    }

    if (data?.status && data?.hash) {
      setHash(data.hash);
      setCodeSendStatus(true);
      setLoading(false);
      toast.info('Doğrulama kodunuz gönderildi.');
      return;
    }

    toast.error(
      'Doğrulama kodu gönderilirken bir hata oluştu. Lütfen tekrar deneyin.',
    );
    setCodeSendStatus(false);
    setLoading(false);
  };

  return (
    <div className="login-wrapper">
      {loading && <Loading />}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{isLoginPage ? 'Giriş Yap' : 'Kayıt Ol'}</Card.Title>
              <Form>
                {!isLoginPage && (
                  <Form.Group>
                    <Form.Label>Ad Soyad</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Telefon Numarası</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Form.Control as="select">
                        <option>+90</option>
                      </Form.Control>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="5XXXXXXXXX"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={handleSendCode}
                        disabled={codeSendStatus}
                      >
                        Kod Gönder
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Doğrulama Kodu</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      autocomplete="one-time-code"
                      placeholder="Telefonunuza gelen doğrulama kodunuzu giriniz"
                      disabled={!codeSendStatus}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Row className="button-container">
                  <Col>
                    <Button
                      className="action-button"
                      variant="primary"
                      onClick={handleClickAction}
                    >
                      {isLoginPage ? 'Giriş Yap' : 'Kayıt Ol'}
                    </Button>
                  </Col>
                  <Col>
                    {isLoginPage ? (
                      <Button
                        variant="link"
                        className="item"
                        href="#"
                        onClick={() => setIsLoginPage(false)}
                      >
                        Kayıt Ol
                      </Button>
                    ) : (
                      <Button
                        variant="link"
                        className="item"
                        href="#"
                        onClick={() => setIsLoginPage(true)}
                      >
                        Giriş Yap
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
