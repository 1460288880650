const localConfig = Object.freeze({
  // API_URL: 'http://localhost/tekurun_ci',
  API_URL: 'https://tekurun.tallhigh.net',
});

const config =
  typeof window.configuration === 'undefined'
    ? localConfig
    : window.configuration;

export default config;
