import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'config';
import 'api/axiosInterceptors';
import { AuthContextProvider } from 'contexts/authContext';
import { ToastContextProvider } from 'contexts/toastContext';
import combineProviders from 'helpers/combineProviders';

const CombinedProviders = combineProviders(
  AuthContextProvider,
  ToastContextProvider,
);

ReactDOM.render(
  <React.StrictMode>
    <CombinedProviders>
      <App />
    </CombinedProviders>
  </React.StrictMode>,
  document.getElementById('root'),
);
