import React from 'react';

const pack = (children = null, ...components) => {
  if (!components.length) {
    return children;
  }

  const [Component, ...rest] = components;

  return <Component>{pack(children, ...rest)}</Component>;
};

const combineProviders = (...components) =>
  function PackComponent({ children }) {
    return pack(children, ...components);
  };

export default combineProviders;
